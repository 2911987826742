import styled from 'styled-components';

export const Button = styled.button`
    padding: 6px 12px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    font-weight: 500;
    cursor: ${props => props.clickable ? 'pointer' : 'default'};
    transition: all 0.2s ease;

    ${props => {
        switch (props.status) {
            case 'aprovada':
            case 'aguardando':
                return `
                    background-color: ${props.theme.palette.success.pale};
                    color: ${props.theme.palette.success.main};
                    ${props.clickable && `
                        &:hover {
                            background-color: ${props.theme.palette.success.main};
                            color: white;
                        }
                    `}
                `;
            case 'inativa':
                return `
                    background-color: ${props.theme.palette.error.pale};
                    color: ${props.theme.palette.error.main};
                `;
            case 'reprovada':
                return `
                    background-color: ${props.theme.palette.error.pale};
                    color: ${props.theme.palette.error.main};
                `;
            default:
                return `
                    background-color: ${props.theme.palette.warning.pale};
                    color: ${props.theme.palette.warning.main};
                `;
        }
    }}
`;