import React, { useEffect } from 'react';
import { 
    DrawerOverlay,
    DrawerContainer,
    DrawerHeader,
    DrawerContent,
    CloseButton
} from './styled';
import { X } from '@phosphor-icons/react';
import FilterContent from './FilterContent';

const Drawer = ({ isOpen, onClose, data }) => {
    console.log("🚀 ~ Drawer ~ data:", data)
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <>
            <DrawerOverlay isOpen={isOpen} onClick={handleOverlayClick}>
                <DrawerContainer isOpen={isOpen}>
                    <DrawerHeader>
                        <h3>Filtros</h3>
                        <CloseButton onClick={onClose}>
                            <X size={24} weight="bold" />
                        </CloseButton>
                    </DrawerHeader>
                    <DrawerContent>
                        <FilterContent onClose={onClose} data={data} />
                    </DrawerContent>
                </DrawerContainer>
            </DrawerOverlay>
        </>
    );
};

export default Drawer; 