import styled from 'styled-components';
import { Card, CardContent, Typography, Box } from '@mui/material';

export const StyledCard = styled(Card)`
    height: auto;
    min-height: 380px;
    background: ${props => props.theme.palette.background.paper};
    border-radius: 20px;
    transition: all 0.3s ease;
    border: none;
    position: relative;
    overflow: hidden;
    margin: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    
    &:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 6px;
        background: linear-gradient(90deg, ${props => props.theme.palette.primary.main}, ${props => props.theme.palette.primary.light});
    }
`;

export const StyledCardContent = styled(CardContent)`
    padding: 24px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const CardTitle = styled(Typography)`
    color: ${props => props.theme.palette.primary.main};
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 32px;
    letter-spacing: 0.5px;
`;

export const StatsContainer = styled(Box)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
    flex: 1;
    padding: 0 8px;
`;

export const StatItemContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 12px;
    border-radius: 12px;
    background: ${props => props.theme.palette.background.default};
    transition: all 0.3s ease;
    min-width: 0;
    height: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.03);
    
    &:hover {
        background: ${props => props.theme.palette.background.paper};
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    }
`;

export const StatLabel = styled(Typography)`
    color: ${props => props.theme.palette.text.secondary};
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 12px;
    width: 100%;
    
    > span {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        font-weight: 500;
        color: ${props => props.theme.palette.text.primary};

        svg {
            width: 20px;
            height: 20px;
        }
    }
    
    strong {
        color: ${props => props.theme.palette.primary.main};
        font-size: 1.4rem;
        font-weight: 600;
        width: 100%;
        text-align: center;
    }

    @media (max-width: 1400px) {
        font-size: 0.85rem;
        
        strong {
            font-size: 1.3rem;
        }

        > span svg {
            width: 18px;
            height: 18px;
        }
    }

    @media (max-width: 1200px) {
        font-size: 0.8rem;
        gap: 8px;
        
        strong {
            font-size: 1.2rem;
        }

        > span {
            gap: 6px;
            
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    @media (max-width: 960px) {
        font-size: 0.75rem;
        
        strong {
            font-size: 1.1rem;
        }
    }
`;

export const CarouselContainer = styled.div`
    position: relative;
    margin-bottom: 32px;
    padding: 0 24px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    .slick-slider {
        width: 100%;
        margin: 0 auto;
    }

    .slick-track {
        display: flex;
        margin: 0 -10px;
    }

    .slick-slide {
        height: auto;
        padding: 0 10px;
        box-sizing: border-box;
        
        > div {
            height: 100%;
        }
    }

    .slick-list {
        margin: 0;
        overflow: hidden;
    }

    .slick-disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
`;

export const SlickArrowButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${props => props.direction === 'left' ? 'left: -20px;' : 'right: -20px;'}
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${props => props.theme.palette.background.paper};
    border: 1px solid ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.2s ease;
    padding: 0;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    &:hover:not(:disabled) {
        background: ${props => props.theme.palette.primary.main};
        color: white;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
        border-color: ${props => props.theme.palette.grey[300]};
        color: ${props => props.theme.palette.grey[300]};
        background: ${props => props.theme.palette.grey[100]};
    }

    &:active:not(:disabled) {
        transform: translateY(-50%) scale(0.95);
    }

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const FullScreenContainer = styled.div`
    &:fullscreen {
        background: ${props => props.theme.palette.background.default};
        padding: 32px;
        overflow-y: auto;
        
        ${CarouselContainer} {
            max-width: 1400px;
            margin: 0 auto;
        }
        
        ${StyledCard} {
            min-height: 420px;
        }
        
        ${CardTitle} {
            font-size: 1.6rem;
        }
        
        ${StatLabel} {
            font-size: 1.1rem;
            
            strong {
                font-size: 1.6rem;
            }
            
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
`; 