import React from 'react';
import { Chart } from "react-google-charts";
import { CircularProgress } from '@mui/material';
import { ChartPie } from '@phosphor-icons/react';
import { 
    LoadingContainer, 
    EmptyStateContainer 
} from './styled';
import { 
    ChartContainer,
    ChartWrapper 
} from './styled';

const DonutChart = ({ data, title, loading }) => {
    const options = {
        pieHole: 0.4,
        is3D: false,
        backgroundColor: 'transparent',
        legend: { 
            position: 'bottom',
            alignment: 'center',
            textStyle: { 
                color: '#D5D96C',
                fontSize: 11
            },
        },
        colors: ['#A3A65B', '#51594C', '#D5D96C', '#D9D5A0'],
        chartArea: { 
            width: '100%',
            height: '75%',
            top: 10,
            bottom: 48
        },
        tooltip: { 
            isHtml: true,
            textStyle: { 
                color: '#D5D96C'
            }
        },
        pieSliceText: 'percentage',
        pieSliceTextStyle: {
            color: '#1A1E17',
            fontSize: 11
        }
    };

    if (loading) {
        return (
            <LoadingContainer>
                <CircularProgress size={40} thickness={4} />
                <span>Carregando dados...</span>
            </LoadingContainer>
        );
    }

    if (!data || data.length <= 1) {
        return (
            <EmptyStateContainer>
                <ChartPie size={48} weight="duotone" />
                <h3>Sem dados disponíveis</h3>
                <p>Não há dados suficientes para gerar o gráfico.</p>
            </EmptyStateContainer>
        );
    }

    return (
        <ChartContainer>
            <h3>{title}</h3>
            <ChartWrapper>
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={options}
                />
            </ChartWrapper>
        </ChartContainer>
    );
};

export default DonutChart; 