import React from 'react';
import {
    CheckCircle,
    XCircle,
    Clock,
    Eye,
    Power,
    Calculator
} from '@phosphor-icons/react';
import {
    StyledCard,
    StyledCardContent,
    CardTitle,
    StatsContainer,
    StatItemContainer,
    StatLabel
} from './styled';

const StatItem = ({ icon: Icon, label, value, color }) => (
    <StatItemContainer>
        <StatLabel>
            <span>
                <Icon size={24} color={color} weight="fill" />
                {label}
            </span>
            <strong>{value}</strong>
        </StatLabel>
    </StatItemContainer>
);

const PatrolStatisticCard = ({ patrol, stats }) => {
    const statItems = [
        { icon: CheckCircle, label: "Aprovadas", value: stats.aprovadas, color: "#2e7d32" },
        { icon: Eye, label: "Visitadas", value: stats.visitadas, color: "#1976d2" },
        { icon: Clock, label: "Aguardando", value: stats.aguardando, color: "#ed6c02" },
        { icon: XCircle, label: "Reprovadas", value: stats.reprovadas, color: "#d32f2f" },
        { icon: Power, label: "Inativas", value: stats.inativas, color: "#757575" },
        { icon: Calculator, label: "Total", value: stats.total, color: "#0288d1" }
    ];

    return (
        <StyledCard>
            <StyledCardContent>
                <CardTitle variant="h6">
                    {patrol.name}
                </CardTitle>
                
                <StatsContainer>
                    {statItems.map((item, index) => (
                        <StatItem
                            key={index}
                            icon={item.icon}
                            label={item.label}
                            value={item.value}
                            color={item.color}
                        />
                    ))}
                </StatsContainer>
            </StyledCardContent>
        </StyledCard>
    );
};

export default PatrolStatisticCard; 