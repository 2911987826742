import styled from 'styled-components';

export const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: ${props => props.theme.palette.background.default}; // Verde escuro do tema
`;

export const MainContent = styled.main`
    flex: 1;
    padding: 20px;
    
    @media (max-width: 768px) {
        padding: 16px;
    }
`; 