import styled from 'styled-components';

export const MapWrapper = styled.div`
    background: ${props => props.theme.palette.background.paper};
    border-radius: 16px;
    padding: 24px;
    width: 100%;
    box-shadow: 0 2px 12px ${props => props.theme.palette.shadow};
    transition: all 0.3s ease;
    border: 1px solid ${props => props.theme.palette.primary.pale};
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
`;

export const StyledMapContainer = styled.div`
    width: 100%;
    height: ${props => props.isFullscreen ? 'calc(100vh - 100px)' : '500px'};
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    .leaflet-container {
        width: 100% !important;
        height: 100% !important;
        background: ${props => props.theme.palette.background.default};
    }

    .leaflet-control-attribution {
        font-size: 10px;
        background-color: rgba(0, 0, 0, 0.6) !important;
        color: #fff !important;

        a {
            color: ${props => props.theme.palette.primary.light} !important;
        }
    }

    .leaflet-popup-content-wrapper {
        background: ${props => props.theme.palette.background.paper};
        color: ${props => props.theme.palette.text.primary};
    }

    .leaflet-popup-tip {
        background: ${props => props.theme.palette.background.paper};
    }

    /* Esconder controles padrão do Leaflet */
    .leaflet-control-zoom {
        display: none;
    }
`;

export const FullscreenMapContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.palette.background.paper};
    z-index: 9999;
    padding: 20px;

    ${MapWrapper} {
        height: 100%;
        margin: 0;
        border-radius: 0;
    }

    ${StyledMapContainer} {
        height: calc(100vh - 100px) !important;
    }
`;

export const MapHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const MapTitle = styled.h3`
    color: ${props => props.theme.palette.text.secondary};
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
`;

export const MapControlsContainer = styled.div`
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1000;
    display: flex;
    gap: 12px;
    background: ${props => `${props.theme.palette.background.paper}CC`};
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 8px ${props => props.theme.palette.shadow};
`;

export const ControlButton = styled.button`
    background: ${props => props.active 
        ? props.theme.palette.primary.main 
        : props.theme.palette.background.paper};
    border: 1px solid ${props => props.theme.palette.primary.pale};
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px ${props => props.theme.palette.shadow};
    width: 40px;
    height: 40px;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px ${props => props.theme.palette.shadow};
        background: ${props => props.active 
            ? props.theme.palette.primary.dark 
            : `${props.theme.palette.primary.main}10`};
        border-color: ${props => props.theme.palette.primary.main};
    }

    svg {
        color: ${props => props.active 
            ? props.theme.palette.common.white 
            : props.theme.palette.primary.main};
    }
`;