/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/contextGlobal/authContext";
import { Wrapper, PageTitle, ContentContainer, ChartGrid, ChartPaper, SectionTitle } from "./styled";
import { Read } from "../../../service/patrols";
import StatisticCard from '../../../components/Cards/StatisticCard';
import ModernTable from '../../../components/Tables/ModernTable';
import StatusButton from '../../../components/Buttons/StatusButton';
import VisitStatusButton from '../../../components/Buttons/VisitStatusButton';
import ModernMap from '../../../components/Maps/ModernMap';
import { useProperties } from '../../../context/PropertiesContext';
import React from 'react';
import { Grid, Paper, Typography, Button, Box, IconButton } from '@mui/material';
import BarChart from '../../../components/Charts/BarChart';
import DonutChart from '../../../components/Charts/DonutChart';
import { Info, ArrowsOut } from '@phosphor-icons/react';
import PatrolStatisticCard from '../../../components/Cards/PatrolStatisticCard';
import Carousel from '../../../components/Cards/PatrolStatisticCard/Carousel';

const HomePage = () => {
    const {
        setPatrols,
        patrols,
        selectedProduct,
        setSelectedProduct,
        setMapCenter,
        setModal
    } = useContext(AuthContext);

    const navigation = useNavigate();
    const { fetchProperties, processedData, filteredData, totals } = useProperties();
    
    // Estado para controlar loading inicial
    const [isLoading, setIsLoading] = useState(true);

    // Adicione este novo estado
    const [patrolStats, setPatrolStats] = useState({});

    // Adicione este estado para controlar o fullscreen
    const [isFullScreen, setIsFullScreen] = useState(false);

    // Adicione esta função para calcular estatísticas por batalhão
    const calculatePatrolStats = (properties) => {
        const stats = {};
        
        properties.forEach(property => {
            if (!property.patrol?.name) return;
            
            const patrolName = property.patrol.name;
            
            if (!stats[patrolName]) {
                stats[patrolName] = {
                    total: 0,
                    aprovadas: 0,
                    reprovadas: 0,
                    visitadas: 0,
                    aguardando: 0,
                    inativas: 0
                };
            }
            
            stats[patrolName].total++;
            
            // Incrementa os contadores baseado no status
            if (property.status?.toLowerCase() === 'ativo' || 
                property.status?.toLowerCase() === 'aprovada' || 
                property.status?.toLowerCase() === 'aprovado') {
                stats[patrolName].aprovadas++;
            }
            if (property.status?.toLowerCase() === 'reprovada' || 
                property.status?.toLowerCase() === 'reprovado') {
                stats[patrolName].reprovadas++;
            }
            if (property.status?.toLowerCase() === 'aguardando') {
                stats[patrolName].aguardando++;
            }
            if (property.status?.toLowerCase() === 'inativo' || 
                property.status?.toLowerCase() === 'inativa') {
                stats[patrolName].inativas++;
            }
            if (property.status_visit?.toLowerCase() === 'visitado' || 
                property.status_visit?.toLowerCase() === 'ativo') {
                stats[patrolName].visitadas++;
            }
        });
        
        return stats;
    };

    // useEffect para inicialização
    useEffect(() => {
        const init = async () => {
            const token = localStorage.getItem('@token');
            if (!token) {
                navigation('/');
                return;
            }

            try {
                setIsLoading(true);
                await fetchProperties();
                await handlePatrols();
            } catch (error) {
                console.error('Erro na inicialização:', error);
            } finally {
                setIsLoading(false);
            }
        };

        init();
    }, [navigation]); // Removido processedData das dependências

    // useEffect separado para calcular estatísticas
    useEffect(() => {
        if (processedData?.length > 0) {
            const stats = calculatePatrolStats(processedData);
            setPatrolStats(stats);
        }
    }, [processedData]);

    const [chartData, setChartData] = useState({
        bar: [],
        donut: [],
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simular carregamento de dados
        setTimeout(() => {
            const mockData = {
                bar: [
                    ["Município", "Ocorrências"],
                    ["São Paulo", 1000],
                    ["Rio de Janeiro", 800],
                    ["Belo Horizonte", 600],
                    ["Salvador", 400],
                    ["Brasília", 300],
                ],
                donut: [
                    ["Município", "Ocorrências"],
                    ["São Paulo", 1000],
                    ["Rio de Janeiro", 800],
                    ["Belo Horizonte", 600],
                    ["Salvador", 400],
                    ["Brasília", 300],
                ],
            };
            setChartData(mockData);
            setLoading(false);
        }, 1500);
    }, []);

    // Colunas da tabela de propriedades
    const columns = [
        {
            field: 'name',
            headerName: 'Nome da Propriedade',
            sortable: true,
            renderCell: (rowData) => rowData.name || 'Não informado'
        },
        {
            field: 'proprietario',
            headerName: 'Nome do Proprietario',
            renderCell: (rowData) => rowData.user?.username || 'Sem proprietário',
            sortable: true
        },
        {
            field: 'property_number',
            headerName: 'Número da Propriedade',
            renderCell: (rowData) => rowData?.property_number || 'Não cadastrado',
            sortable: true
        },
        {
            field: 'area',
            headerName: 'Área',
            sortable: true,
            renderCell: (rowData) => rowData.area || 'Não informado'
        },
        {
            field: 'municipality',
            headerName: 'Endereço',
            sortable: true,
            renderCell: (rowData) => rowData.municipality || 'Não informado'
        },
        {
            field: 'line',
            headerName: 'Linha',
            sortable: true,
            renderCell: (rowData) => rowData.line || 'Não informado'
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData?.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (rowData) => (
                <StatusButton status={rowData.status} rowData={rowData} />
            ),
            sortable: true
        },
        {
            field: 'status_visit',
            headerName: 'Visita',
            renderCell: (rowData) => (
                <VisitStatusButton status={rowData.status_visit} propertyId={rowData.id} />
            ),
            sortable: true
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (rowData) => (
                <Button
                    variant="contained"
                    color="info"
                    startIcon={<Info weight="duotone" />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setModal({
                            type: 'propertyInfo',
                            data: rowData
                        });
                    }}
                    sx={{
                        textTransform: 'none',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                            transform: 'translateY(-1px)',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                        }
                    }}
                >
                    Informações
                </Button>
            ),
            sortable: false
        }
    ];

    const handlePatrols = async () => {
        try {
            const response = await Read();
            if (response?.data) {
                const formattedPatrols = response.data.map(patrol => ({
                    id: patrol.id,
                    name: patrol.attributes.name,
                    code: patrol.attributes.code,
                    license_plate: patrol.attributes.license_plate
                }));
                setPatrols(formattedPatrols);
            }
        } catch (error) {
            console.error("Erro ao buscar batalhes:", error);
        }
    };

    const handleRowClick = (rowData) => {
        setSelectedProduct(rowData);
        if (rowData.status?.toLowerCase() === 'aguardando') {
            setModal({
                type: 'chooseAction',
                data: rowData
            });
        }
    };

    // Adicione esta função para controlar o fullscreen
    const handleFullScreen = () => {
        const element = document.getElementById('patrol-stats-section');
        if (!document.fullscreenElement) {
            element.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            document.exitFullscreen();
        }
        setIsFullScreen(!isFullScreen);
    };

    return (
        <ContentContainer>
            <PageTitle>Propriedades</PageTitle>

            <Wrapper>
                <StatisticCard title="Total" value={totals.total} />
                <StatisticCard title="Total Reprovadas" value={totals.reprovadas} />
                <StatisticCard title="Total Visitadas" value={totals.visitadas} />
                <StatisticCard title="Total Aguardando" value={totals.aguardando} />
                <StatisticCard title="Total Aprovadas" value={totals.aprovadas} />
                <StatisticCard title="Total Inativas" value={totals.inativas} />
            </Wrapper>

            <div id="patrol-stats-section">
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    mt: 4, 
                    mb: 2 
                }}>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            color: 'primary.main',
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}
                    >
                        Estatísticas por Batalhão
                    </Typography>
                    <IconButton
                        onClick={handleFullScreen}
                        sx={{
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        <ArrowsOut size={24} />
                    </IconButton>
                </Box>

                <Carousel sx={{ mb: 4 }}>
                    {patrols.map((patrol) => (
                        <PatrolStatisticCard 
                            key={patrol.id}
                            patrol={patrol}
                            stats={patrolStats[patrol.name] || {
                                total: 0,
                                aprovadas: 0,
                                reprovadas: 0,
                                visitadas: 0,
                                aguardando: 0,
                                inativas: 0
                            }}
                        />
                    ))}
                </Carousel>
            </div>

            <ModernMap
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                properties={filteredData?.length > 0 ? filteredData : processedData}
            />

            <SectionTitle>
                <h2>Lista de Propriedades</h2>
            </SectionTitle>

            <ModernTable
                columns={columns}
                data={filteredData?.length > 0 ? filteredData : processedData}
                pagination={true}
                onRowClick={handleRowClick}
                rowsPerPageOptions={[5, 10, 25]}
                loading={isLoading}
            />

            <SectionTitle>
                <h2>Análise de Ocorrências</h2>
            </SectionTitle>

            <ChartGrid>
                <ChartPaper>
                    <BarChart
                        data={chartData.bar}
                        title="Ocorrências por Município"
                        loading={loading}
                    />
                </ChartPaper>
                <ChartPaper>
                    <DonutChart
                        data={chartData.donut}
                        title="Distribuição de Ocorrências"
                        loading={loading}
                    />
                </ChartPaper>
            </ChartGrid>
        </ContentContainer>
    );
};

export default HomePage;
