import styled from 'styled-components';

export const ChartContainer = styled.div`
    width: 100%;
    height: 400px;
    position: relative;
    margin: 20px 0px;
`;

export const ChartWrapper = styled.div`
    .google-visualization-tooltip {
        background-color: ${props => props.theme.palette.background.paper} !important;
        border: 1px solid ${props => props.theme.palette.primary.dark} !important;
        border-radius: 8px !important;
        padding: 12px !important;
        color: ${props => props.theme.palette.text.primary} !important;
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    gap: 16px;

    span {
        color: ${props => props.theme.palette.text.secondary};
    }
`;

export const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    gap: 12px;
    color: ${props => props.theme.palette.text.secondary};

    svg {
        color: ${props => props.theme.palette.primary.light};
    }

    h3 {
        margin: 0;
        color: ${props => props.theme.palette.text.primary};
    }

    p {
        margin: 0;
        font-size: 0.9rem;
    }
`; 