import React from 'react';
import Slider from 'react-slick';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { CarouselContainer, SlickArrowButton } from './styled';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = ({ onClick }) => (
    <SlickArrowButton direction="right" onClick={onClick}>
        <CaretRight size={24} weight="bold" />
    </SlickArrowButton>
);

const PrevArrow = ({ onClick }) => (
    <SlickArrowButton direction="left" onClick={onClick}>
        <CaretLeft size={24} weight="bold" />
    </SlickArrowButton>
);

const Carousel = ({ children }) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        variableWidth: false,
        adaptiveHeight: false,
        centerMode: false,
        swipeToSlide: true,
        className: "patrol-carousel"
    };

    return (
        <CarouselContainer>
            <Slider {...settings}>
                {children}
            </Slider>
        </CarouselContainer>
    );
};

export default Carousel; 